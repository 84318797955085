import React from "react";
import { Button } from "@/components/ui/button";
import { MailX } from "lucide-react";

type Props = {};

const UnsubscribePage = (props: Props) => {
  const handleSubmit = async (e: any) => {
    //Subscribe again actions
    console.log("Subscribed again");
  };
  return (
    <>
      <div className="flex h-screen justify-center items-center">
        <div className="container relative flex-col items-center justify-center">
          <div className="flex justify-center">
            <div className="flex px-4 sm:p-8 grow max-w-lg flex-col bg-White/30 justify-center space-y-6 sm:border sm:shadow-xl sm:shadow-blue-brand/10 sm:rounded-xl">
              <div className="flex flex-col text-center">
                <div className="flex justify-center sm:py-6">
                  <MailX className="w-12 h-12" />
                </div>
                <h1 className="text-2xl font-semibold tracking-tight">You're Unsubscribed!</h1>
                <p className="text-sm text-muted-foreground text-justify pb-3 pt-6 sm:pt-10">
                  We're sorry to loose you, but we totally understand. Did you unsubscribe by mistake? Don't worry about
                  it! You can resubcribe instantly by clicking the button below:
                </p>
              </div>
              <Button onClick={handleSubmit} className="text-foreground">
                Subscribe
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UnsubscribePage;
