import React, { HTMLAttributes, SyntheticEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthStore } from "../auth-store";
import { fromZodError } from "zod-validation-error";
import { z } from "zod";
import { cn } from "@/lib/utils";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import ReCAPTCHA from "react-google-recaptcha";

interface SigninWihPageFormProps extends HTMLAttributes<HTMLDivElement> {}

const emailSchema = z.string().email();

const SigninWithMailPageForm = ({ className }: SigninWihPageFormProps) => {
  const navigate = useNavigate();
  const [isDisable, setIsDisable] = useState<boolean>(true);
  const { sendSignInLinkToEmail } = useAuthStore((state) => state);
  const [formInput, setFormInput] = useState({
    email: "",
  });
  const [message, setMessage] = useState({
    message: "",
  });
  const [captchaValue, setCaptchaValue] = useState<boolean>();

  useEffect(() => {
    const { success, error } = emailSchema.safeParse(formInput.email);
    if (error) {
      const validationError = fromZodError(error).toString().replace("Validation error:", "");
      //console.log(validationError);
      setMessage({
        message: validationError,
      });
    } else {
      console.log("Email valid");
    }
    setIsDisable(!captchaValue || !success);
  }, [captchaValue, formInput]);

  async function onSubmit(event: SyntheticEvent) {
    event.preventDefault();
  }
  const handleSubmit = async (e: any) => {
    try {
      await sendSignInLinkToEmail(formInput.email);
      console.log(formInput.email);
      navigate("/auth/signin-link-sent");
    } catch (error) {
      console.error("Unable to send password reset email:", error);
    }
  };
  return (
    <div className={cn("grid gap-6", className)}>
      <form onSubmit={onSubmit}>
        <div className="grid gap-2">
          <div className="grid gap-1">
            <Label className="sr-only" htmlFor="email">
              Email
            </Label>
            <Input
              id="email"
              placeholder="name@example.com"
              type="email"
              autoCapitalize="none"
              autoComplete="email"
              autoCorrect="off"
              onChange={({ target }) =>
                setFormInput({
                  email: target.value,
                })
              }
            />
          </div>
          <ReCAPTCHA
            className="relative px-1 sm:px-10"
            sitekey="6Lcei4MpAAAAAHFArVmUigCLGj-8DIG1G0igevO7"
            onChange={(val: boolean) => setCaptchaValue(val)}
          />
          <Button disabled={isDisable} onClick={handleSubmit} className="text-foreground">
            Send Email
          </Button>
          {captchaValue && message && isDisable ? (
            <div className="flex justify-center text-sm text-red-300 font-sans">{message.message}</div>
          ) : (
            ""
          )}
        </div>
      </form>
    </div>
  );
};

export default SigninWithMailPageForm;
