import { HeaderAuth } from "@/components/dashboard/header/headerAuth";
import AddDevice from "@/components/mode-partner/device/addDevice";
import CustomerTable from "@/components/mode-partner/customer/customerTable";

export const PartnerPage = () => {
  return (
    <>
      <div className="min-h-screen block bg-background">
        <HeaderAuth />
        <div className="p-8">
          <main className="flex flex-row w-full justify-between">
            <div className="">
              <h1 className="text-2xl font-medium pb-8">Registered devices</h1>
              <div className="">
                <CustomerTable />
              </div>
            </div>
            <div className="">
              <AddDevice />
            </div>
          </main>
        </div>
      </div>
    </>
  );
};
