export const Customers: {
  id: string;
  firstName: string;
  middleName?: string | null;
  lastName: string;
  suffix?: string | null;
  emailAddress: string;
  phoneNumber: string;
  mailingAddress: string;
}[] = [
  {
    id: "1",
    firstName: "Henry",
    lastName: "Nestle",
    emailAddress: "henry1990@gmail.com",
    phoneNumber: "+13057640590",
    mailingAddress: "Street A, between 1st and 2nd, Arizona",
  },
  {
    id: "2",
    firstName: "Steve",
    lastName: "Jobs",
    emailAddress: "steve1990@gmail.com",
    phoneNumber: "+13057640590",
    mailingAddress: "Street A, between 1st and 2nd, Arizona",
  },
  {
    id: "3",
    firstName: "Gustave",
    lastName: "Eiffel",
    emailAddress: "gustave1990@gmail.com",
    phoneNumber: "+13057640590",
    mailingAddress: "Street A, between 1st and 2nd, Arizona",
  },
  {
    id: "4",
    firstName: "Clive",
    lastName: "Lewis",
    emailAddress: "lewis1990@gmail.com",
    phoneNumber: "+13057640590",
    mailingAddress: "Street A, between 1st and 2nd, Arizona",
  },
  {
    id: "5",
    firstName: "Bonnano",
    lastName: "Pissano",
    emailAddress: "john1990@gmail.com",
    phoneNumber: "+13057640590",
    mailingAddress: "Street A, between 1st and 2nd, Arizona",
  },
  {
    id: "6",
    firstName: "Alexander",
    lastName: "Bell",
    emailAddress: "alexander1990@gmail.com",
    phoneNumber: "+13057640590",
    mailingAddress: "Street A, between 1st and 2nd, Arizona",
  },
];
