import { BrowserRouter, Route, Routes } from "react-router-dom";
import SignIn from "./components/auth/signin/SignIn";
import AuthAction from "./components/auth/action/AuthAction";
import { RecoverEmail } from "./components/auth/action/recover-email/RecoverEmail";
import { PasswordResetMailSent } from "./components/auth/action/reset-password/PasswordResetMailSent";
import { PasswordResetRequest } from "./components/auth/action/reset-password/PasswordResetRequest";
import { SetNewPassword } from "./components/auth/action/reset-password/SetNewPassword";
import { SetNewPasswordSuccess } from "./components/auth/action/reset-password/SetNewPasswordSuccess";
import { UpdateEmail } from "./components/auth/action/update-email/UpdateEmail";
import { VerifyMail } from "./components/auth/action/verify-email/VerifyEmail";
import { VerifyEmailRequest } from "./components/auth/action/verify-email/VerifyEmailRequest";
import SignUp from "./components/auth/signup/SignUp";
import Dashboard from "./pages/Dashboard";
import { DefaultPageMain } from "./components/DefaultPage/main";
import { DashboardMain } from "./components/dashboard/dashboardMain/dashboardMain";
import AuthMain from "./pages/AuthMain";
import { PartnerPage } from "./pages/PartnerPage";
import CompleteSignUp from "./components/auth/action/complete-signup/CompleteSignUp";
import WelcomeUser from "./components/dashboard/welcome-user/welcomeUser";
import SignInWithMailPage from "./components/auth/signinwithMail/SignInWithMailPage";
import { EmailLinkSentPage } from "./components/auth/signinwithMail/EmailLinkSentPage";
import { PrivateRoute } from "./PrivateRoute";
import UnsubscribePage from "./components/auth/unsubscribe/unsubscribePage";

export const RoutesGuardian = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<DefaultPageMain />} />
        <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              <DashboardMain />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/partner"
          element={
            <PrivateRoute>
              <PartnerPage />
            </PrivateRoute>
          }
        ></Route>

        <Route path="/auth" element={<AuthMain />}>
          <Route path="signin" element={<SignIn />} />
          <Route path="signup" element={<SignUp />} />
          <Route path="action" element={<AuthAction />} />
          <Route path="set-new-password-success" element={<SetNewPasswordSuccess />} />
          <Route path="password-reset" element={<PasswordResetRequest />} />
          <Route path="password-reset-mail-sent" element={<PasswordResetMailSent />} />
          <Route path="verify-mail-request" element={<VerifyEmailRequest />} />
          <Route path="update-mail" element={<UpdateEmail />} />
          <Route path="complete-signup" element={<CompleteSignUp />} />
          <Route path="signin-link" element={<SignInWithMailPage />} />
          <Route path="signin-link-sent" element={<EmailLinkSentPage />} />
          <Route path="unsubscribe" element={<UnsubscribePage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
