import { HTMLAttributes, SyntheticEvent, useState } from "react";
import { auth, useAuthStore } from "../../auth-store";
import { Button } from "../../../ui/button";
import { useToast } from "../../../ui/use-toast";
import { ToastAction } from "@/components/ui/toast";
import { useNavigate } from "react-router-dom";

interface SetPasswordFormProps extends HTMLAttributes<HTMLDivElement> {
  code: string;
}

export const VerifyEmailForm = ({ code }: SetPasswordFormProps) => {
  const { verifyPasswordResetCode, applyActionCode } = useAuthStore((state) => state);
  const [verify, setVerify] = useState<boolean>(false);
  const navigate = useNavigate();
  const { toast } = useToast();

  const handleSubmit = async (e) => {
    try {
      await verifyPasswordResetCode(code);
      await applyActionCode(auth, code).then((resp) => {
        console.log("Apply action code");
        setVerify(true);
      });
    } catch (error) {
      toast({
        variant: "destructive",
        title: "Error!",
        description: "It is not possible to verify your email address at this time.",
        className: "flex flex-inline py-3",
        action: (
          <ToastAction
            className="flex px-3 my-3 border-2 rounded-lg text-sm text-center"
            altText="Retry"
            onClick={() => {
              navigate("/auth/verify-mail-request");
            }}
          >
            Retry
          </ToastAction>
        ),
      });
    }
  };

  return (
    <div className="flex flex-col justify-center font-sans">
      {verify ? (
        <div className="font-semibold text-base text-green-600 text-sm text-muted-foreground text-center pb-6 pt-3">
          Email Verified!!!
        </div>
      ) : (
        ""
      )}
      <Button variant="default" type="button" onClick={handleSubmit}>
        Verify
      </Button>
    </div>
  );
};
