import React from "react";
import { RoutesGuardian } from "./RoutesGuardian.tsx";
import { ThemeProvider } from "@/components/ui/theme-provider.tsx";
import { Toaster } from "./components/ui/toaster";

function App() {
  return (
    <React.StrictMode>
      <ThemeProvider defaultTheme="light" storageKey="vite-ui-theme">
        <RoutesGuardian />
        <Toaster />
      </ThemeProvider>
    </React.StrictMode>
  );
}

export default App;
