import { useSearchParams } from "react-router-dom";
import { SetNewPassword } from "./reset-password/SetNewPassword";
import { RecoverEmail } from "./recover-email/RecoverEmail";
import { VerifyMail } from "./verify-email/VerifyEmail";
import SignIn from "@/components/auth/signin/SignIn.tsx";

const AuthAction = () => {
  const [searchParams] = useSearchParams();
  const mode: string | null = searchParams.get("mode");
  const oobCode: string | null = searchParams.get("oobCode");
  console.log("auth action mode", mode);

  if (oobCode === null) {
    return <div>invalid oob code</div>;
  }

  switch (mode) {
    case "resetPassword":
      return <SetNewPassword code={oobCode} />;
    case "recoverEmail":
      return <RecoverEmail code={oobCode} />;
    case "verifyEmail":
      return <VerifyMail code={oobCode} />;
    case "signIn":
      // TODO: show a spinner instead
      // here the application should redirect quickly once the `signInWithEmailLink` is called
      return <div>Signing in...</div>;

    default:
      return <div>Invalid mode</div>;
  }
};

export default AuthAction;
