import { Navigate } from "react-router-dom";
import { useAuthStore } from "./components/auth/auth-store";
import { Skeleton } from "./components/ui/skeletor";

export const PrivateRoute = ({ children }) => {
  const { user, initialized } = useAuthStore((state) => state);
  console.log("PrivateRoute user:", user, "initialized:", initialized);

  if (!initialized) {
    // TODO: Add a loading spinner
    return (
      <div className="relative flex flex-col min-h-screen justify-center items-center">
        <div className="absolute top-0 left-2 right-0 z-30">
          <Skeleton className="h-14 w-full rounded-xl" />
        </div>
        <div className="h-24 w-full"></div>
        <div className="flex flex-col p-8 w-full max-w-screen-2xl space-y-8">
          <Skeleton className="h-80 max-w-screen-2xl rounded-xl" />
          <div className="space-y-4">
            <Skeleton className="h-10 max-w-screen-2xl]" />
            <Skeleton className="h-10 max-w-screen-xl" />
          </div>
        </div>
        <div className="flex flex-col p-8 w-full max-w-screen-2xl space-y-8">
          <Skeleton className="h-80 max-w-screen-2xl rounded-xl" />
          <div className="space-y-4">
            <Skeleton className="h-10 max-w-screen-2xl]" />
            <Skeleton className="h-10 max-w-screen-xl" />
          </div>
        </div>
      </div>
    );
  }

  if (user) {
    return children;
  }

  return <Navigate to="/auth/signin" />;
};
