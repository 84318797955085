import React from "react";
import { CalendarDateRangePicker } from "../dashboardBody/data-range-picker";
import { Button } from "@/components/ui/button";

type Props = {};

const Reports = (props: Props) => {
  return (
    <>
      <div className="flex items-center space-x-2">
        <CalendarDateRangePicker />
        <Button>Download</Button>
      </div>
    </>
  );
};

export default Reports;
