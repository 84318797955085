import { ModeToggle } from "@/components/ui/mode-toggle";
import { UserMenu } from "../header/UserMenu";
import { ModeNotify } from "@/components/ui/mode-notify";
import { ModeContact } from "@/components/ui/mode-contact";
import WhiteLogo from "@/assets/svg/Color-logo-no-background.svg";
import DarkLogo from "@/assets/Logo-DarkMode.png";
import { useTheme } from "@/components/ui/theme-provider";

export const HeaderDashboard = () => {
  const { theme } = useTheme();

  const showLogo = () => {
    console.log("Theme", theme);
    if (theme === "dark") {
      return <img src={DarkLogo} alt="DarkLogo" className="h-10 md:h-10 object-fill"></img>;
    } else {
      return <img src={WhiteLogo} alt="WhiteLogo" className="h-10 md:h-10 object-fill"></img>;
    }
  };

  return (
    <header className="border border-input sm:h-auto sm:border-0">
      <div className="flex h-16 w-full items-center bg-cyan-verydark/70 shadow-none backdrop-blur">
        <div className="mr-4 overflow-x-hidden md:flex">
          <div className="flex items-center pr-6 mb-2 mx-2 sm:mx-4">{showLogo()}</div>
        </div>
        <div className="flex flex-1 ml-auto items-center justify-between space-x-2 md:justify-end mr-2">
          <div className="hidden md:flex md:flex-row justify-center items-center space-x-2 text-cyan-grayish">
            <ModeContact />
            <ModeNotify />
            <ModeToggle />
          </div>
        </div>
        <div className="mx-6 pl:4 sm:pl-0 sm:mx-0">
          <UserMenu />
        </div>
      </div>
    </header>
  );
};
