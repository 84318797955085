import { Link } from "react-router-dom";

export const EmailLinkSentPage = () => {
  return (
    <>
      <div className="relative pt-36 pb-24 px-3 flex items-center justify-center">
        <div className="relative sm:w-[720px] justify-center sm:border sm:shadow-lg">
          <div className="sm:p-8">
            <div className="mx-auto flex w-full flex-col justify-center space-y-3 sm:space-y-6">
              <div className="flex flex-col text-center">
                <h1 className="text-2xl font-semibold tracking-tight">Email link request sent!</h1>
                <p className="text:lg px-2 text-muted-foreground text-center text-indigo-500 pt-6 pb-3 sm:pt-10 sm:text-xl">
                  You can check your email box to continue sign in with email link.
                </p>
              </div>
              <p className="text-sm text-center text-muted-foreground text-indigo-500">
                Go to{" "}
                <Link
                  to="/auth/signin"
                  className="underline underline-offset-4 hover:text-primary font-semibold text-lg ml-1"
                >
                  Sign In
                </Link>{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
