import { create, StoreApi, UseBoundStore } from "zustand";

export type DashboardStoreState = {
  loadingDevices: boolean;
  loadingHistoricalData: boolean;
  showMetric: boolean | string;
  toggleShowMetric: () => void;
  devices: {
    [id: string]: {
      name: string | undefined;
      kernelId: string;
      subscription?: {
        active: boolean;
        lastPayment: Date | undefined;
      };
      hardware: {
        refrigerant: string;
        txv: boolean;
      };
    };
  };
};

export type DashboardStoreActions = {
  loadDevices: () => Promise<void>;
  setHistoricalDataLoading: (loading: boolean) => void;
};

export type DashboardStore = DashboardStoreState & DashboardStoreActions;

export const useDashboardStore: UseBoundStore<StoreApi<DashboardStore>> = create<DashboardStore>((set, get) => ({
  loadingDevices: true,
  loadingHistoricalData: true,
  showMetric: false,
  toggleShowMetric: () => set((state) => ({ showMetric: !state.showMetric })),
  devices: {},
  setHistoricalDataLoading: (loading) => set({ loadingHistoricalData: loading }),
  async loadDevices() {
    // TODO: read the list of devices from the API
    set(() => ({
      loadingDevices: false,
      devices: {
        "84EF4776EFD0": {
          name: "Lias",
          kernelId: "ok38a20pjz4ow998hvyb3rmo",
          subscription: {
            active: true,
            // 1 month ago from now
            lastPayment: new Date(Date.now() - 3 * 24 * 60 * 60 * 1000),
          },
          hardware: {
            refrigerant: "R410A",
            txv: false,
          },
        },
      },
    }));
  },
}));
