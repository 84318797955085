import { useNavigate } from "react-router-dom";
import { SignUpForm } from "./SignUpForm";

//type Props = {}

const SignUp = () => {
  const navigate = useNavigate();

  return (
    <div className="flex h-screen justify-center items-center">
      <div className="container relative flex-col items-center justify-center">
        <div className="flex justify-center">
          <div className="flex px-4 sm:p-8 grow max-w-lg flex-col bg-White/30 justify-center space-y-6 sm:border sm:shadow-xl sm:shadow-blue-brand/10 sm:rounded-xl">
            <div className="flex flex-col space-y-2 text-center">
              <h1 className="text-2xl font-semibold tracking-tight text-foreground">Create an account</h1>
            </div>
            <div className="flex justify-center text-sm font-medium space-x-1">
              <h2 className="text-muted-foreground">Have an account??</h2>
              <label
                className="underline peer-disabled:cursor-allowed peer-disabled:opacity-70 float-right cursor-pointer text-foreground text-sm leading-tight"
                onClick={() => navigate("/auth/signin")}
              >
                Sign in
              </label>
            </div>
            <SignUpForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
