import { RecoverEmailForm } from "./RecoverEmailForm";

interface RecoverEmailProps {
  code: string;
}

export const RecoverEmail = ({code}: RecoverEmailProps) => {
  return (
    <>
    
      <div className="relative pt-10 pb-24 flex items-center justify-center ">
        <div className="relative sm:w-[520px] justify-center sm:border">
          <div className="sm:p-8">
            <div className="mx-auto flex w-full flex-col justify-center space-y-3 sm:space-y-6 sm:w-[350px]">
              <div className="flex flex-col text-center">
                <h1 className="text-2xl font-semibold tracking-tight">
                  Recover your email
                </h1>
              </div>
              <RecoverEmailForm code={code} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
};