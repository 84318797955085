import React, { useState } from "react";
import { useEffect } from "react";
import { useDashboardStore } from "../dashboard-store";

export const DigitalValues = ({ data, data$ }) => {
  const [value, setValue] = React.useState(data.value);
  const { showMetric, loadingHistoricalData } = useDashboardStore((state) => state);

  useEffect(() => {
    const subscription = data$.subscribe((d) => {
      const values = d[data.sensorId];
      if (!values) {
        console.warn("No values for sensor", data.sensorId, "data", d);
        return;
      }
      //console.log("values", values, "sensorId", data.sensorId, "d", d);
      showMetric ? "" : setValue(values[values.length - 1].value);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [data$, data.sensorId]);

  function toMetric(value: number, data: string): number {
    if (data.includes("temperature")) {
      const temperatureConverted: number = ((value - 32) * 5) / 9;
      return temperatureConverted;
    } else if (data.includes("pressure")) {
      const pressureConverted: number = value * 0.1450377377;
      return pressureConverted;
    } else {
      return value;
    }
  }

  // show a loading spinner while loading the historical data
  return loadingHistoricalData ? (
    <div className="flex justify-center items-center">...</div>
  ) : (
    <div className="flex w-full flex-row items-center justify-center">
      <span className="flex text-5xl font-bold text-foreground dark:text-blue-brand justify-start pr-2">
        {showMetric ? Math.round(toMetric(value, data.sensorId)) : Math.round(value)}
      </span>
      <span className="flex text-lg font-normal text-foreground dark:text-blue-brand justify-end">{data.unit}</span>
    </div>
  );
};

/*
Formula to change measure system
 °C = (°F - 32) × 5/9.
1 kPa = 0.1450377377 psi
      {showMetric ? calculateUnits(value, data.sensorId) : value.toFixed(1)}
*/
