import { Button } from "@/components/ui/button";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "./dropdown-menu";
import { PhoneCall } from "lucide-react";

export function ModeContact() {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="outline"
          size="icon"
          className="border-none shadow-none inline-flex items-center justify-center h-8 w-8 font-bold text-cyan-grayish bg-transparent"
        >
          <PhoneCall className="h-[1.4rem] w-[1.4rem]" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuItem>Maintenance Email</DropdownMenuItem>
        <DropdownMenuItem>Maintenance Phone</DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
