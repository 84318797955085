import { Send } from "lucide-react";

export const PasswordResetMailSent = () => {
  return (
    <>
      <div className="relative pt-36 pb-24 px-3 flex items-center justify-center ">
        <div className="relative max-w-lg justify-center sm:border sm:shadow-lg">
          <div className="sm:p-8">
            <div className="relative mx-auto flex w-full flex-col justify-center space-y-3 sm:space-y-6">
              <div className="flex flex-col text-center justify-center">
                <div className="flex items-center justify-center">
                  <h1 className="text-2xl font-semibold tracking-tight">EMAIL SENT!</h1>
                  <Send size={24} strokeWidth={1.75} />
                </div>
                <p className="text-sm font-sans px-3.5 text-muted-foreground text-justify text-indigo-500 pt-6 pb-3 sm:pt-10 sm:text-base">
                  An email has been sent to your mailbox. Please follow the steps described to continue resetting your
                  password.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
