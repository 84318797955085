import React, { useEffect, useState } from "react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { concatMap, delay, of, Subject } from "rxjs";
import { ResponsiveContainer } from "recharts";
import Reports from "../../dashboard/reports/Reports";
import { DigitalValues } from "../../dashboard/echart/digitalValues";
import { EChartsStreamVisualizer } from "../../dashboard/echart/EChartsStreamVisualizer";
import { Checkbox } from "@/components/ui/checkbox";
import { useDashboardStore } from "../../dashboard/dashboard-store";
import { getStreamFromWS } from "@/components/dashboard/echart/getStreamFromWS.ts";
import { useAuthStore } from "@/components/auth/auth-store";
import { useNavigate, useSearchParams } from "react-router-dom";
import WelcomeUser from "../welcome-user/welcomeUser";
import { HeaderDashboard } from "../header/headerDashboard";
import { format, formatDistance } from "date-fns";
import { enUS } from "date-fns/locale";
import { SuperHeat } from "@/components/dashboard/gauges/SuperHeat.tsx";
import { SubCooling } from "@/components/dashboard/gauges/SubCooling.tsx";
import { getPsiToF, Refrigerant } from "@/utils/refrigerants.ts";

type SensorList = {
  data$: Subject<{
    [sensorId: string]: { time: number; value: number }[];
  }>;
  // options to customize the chart
  options?: any;
  sensorType?: "pressure" | "temperature" | "current";
  measure?: { dual: boolean; imperial: string; metric: string };
};

export const DashboardMain = () => {
  const clear$ = new Subject<void>();
  const show$ = new Subject<boolean>();
  const { isNewUser } = useAuthStore((state) => state);
  const [searchParams] = useSearchParams();

  const [data$, setData$] = useState<Subject<Record<string, { time: number; value: number }[]>> | null>(null);
  const [deviceId, setDeviceId] = useState<string | null>(null);
  const [lastSensorUpdate, setLastSensorUpdate] = useState<{ [sensorType: string]: Date }>({});

  const { loadDevices, devices, loadingHistoricalData, setHistoricalDataLoading, showMetric, toggleShowMetric } =
    useDashboardStore((state) => state);

  useEffect(() => {
    // load devices on mount
    loadDevices()
      .then(() => {
        console.log("Devices loaded");
      })
      .catch((error) => {
        console.error("Error loading devices", error);
      });
  }, []);

  useEffect(() => {
    // get the kernel id from the device list
    if (Object.keys(devices).length === 0) return;

    const deviceId = searchParams.get("deviceId") ?? Object.keys(devices)[0];
    const kernelId = searchParams.get("kernelId") ?? devices[deviceId].kernelId;
    setDeviceId(deviceId);
    console.log("deviceId", deviceId, "kernelId", kernelId);
    const stream$ = getStreamFromWS(kernelId)
      // ensure at least 100 ms between emissions
      .pipe(concatMap((s) => of(s).pipe(delay(5)))) as Subject<
      Record<
        string,
        {
          time: number;
          value: number;
        }[]
      >
    >;
    setData$(stream$);
    show$.next(false);
    stream$.subscribe((data) => {
      if (loadingHistoricalData) {
        console.log("Historical data loaded");
        setHistoricalDataLoading(false);
        show$.next(true);
      }
      // get the timestamp of the last update for each sensor
      const lastUpdateTemperature1 = data.temperatureIn[data.temperatureIn.length - 1].time;
      const lastUpdateTemperature2 = data.temperatureOut[data.temperatureOut.length - 1].time;
      const lastUpdatePressure1 = data.pressureIn[data.pressureIn.length - 1].time;
      const lastUpdatePressure2 = data.pressureOut[data.pressureOut.length - 1].time;
      const lastUpdateCurrent1 = data.current1 ? data.current1[data.current1.length - 1].time : 0;
      const lastUpdateCurrent2 = data.current2 ? data.current2[data.current2.length - 1].time : 0;
      const lastUpdateCurrent3 = data.current3 ? data.current3[data.current3.length - 1].time : 0;
      setLastSensorUpdate({
        pressure: new Date(Math.max(lastUpdatePressure1, lastUpdatePressure2)),
        temperature: new Date(Math.max(lastUpdateTemperature1, lastUpdateTemperature2)),
        current: new Date(Math.max(lastUpdateCurrent1, lastUpdateCurrent2, lastUpdateCurrent3)),
      });
    });
  }, [devices]);

  // TODO: this should come from the backend eventually
  const sensorList: SensorList[] = [
    {
      sensorType: "pressure",
      measure: { dual: true, imperial: "PSI", metric: "kP" },
      data$,
      options: {
        series: [
          {
            title: "Low Line",
            radius: "95%",
            type: "gauge",
            center: ["50%", "60%"],
            startAngle: 220,
            endAngle: -40,
            min: 0,
            max: 300,
            z: 200,
            splitNumber: 10,
            progress: {
              show: true,
              overlap: false,
              roundCap: false,
            },
            pointer: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                width: 35,
              },
            },
            axisTick: {
              distance: -45,
              splitNumber: 5,
              lineStyle: {
                width: 2,
                color: "#999",
              },
            },
            splitLine: {
              distance: -52,
              length: 8,
              lineStyle: {
                width: 2,
                color: "#999",
              },
            },
            axisLabel: {
              distance: 7,
              color: "#999",
              fontSize: 10,
            },
            detail: {
              show: false,
            },
            data: [
              {
                sensorId: "pressureIn",
                value: 0,
                // this should match one of the keys in the object emitted by the data$ observable
                unit: showMetric ? "kP" : "PSI",
                progress: {
                  show: true,
                  width: 20,
                },
                detail: {
                  valueAnimation: true,
                },
                pointer: {
                  show: true,
                },
                itemStyle: {
                  color: "hsl(191.5,100%,51.8%)",
                  shadowColor: "#FD7349",
                },
              },
              {
                sensorId: "temperatureIn",
                value: 0,
                // this should match one of the keys in the object emitted by the data$ observable
                unit: showMetric ? "℃" : "℉",
                pointer: {
                  show: false,
                },
                detail: {
                  valueAnimation: true,
                },
                itemStyle: {
                  color: "hsl(191.9,100%,84.1%)",
                  shadowColor: "#FD7349",
                },
              },
            ],
          },
        ],
      },
    },
    {
      sensorType: "temperature",
      measure: { dual: true, imperial: "℉", metric: "℃" },
      data$,
      options: {
        series: [
          {
            title: "High Line",
            min: 0,
            max: 450,
            radius: "95%",
            center: ["50%", "60%"],
            startAngle: 220,
            endAngle: -40,
            type: "gauge",
            splitNumber: 10,
            progress: {
              show: true,
              overlap: false,
              roundCap: false,
            },
            pointer: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                width: 35,
              },
            },
            axisTick: {
              distance: -45,
              splitNumber: 5,
              lineStyle: {
                width: 2,
                color: "#999",
              },
            },
            splitLine: {
              distance: -52,
              length: 8,
              lineStyle: {
                width: 2,
                color: "#999",
              },
            },
            axisLabel: {
              distance: 7,
              color: "#999",
              fontSize: 10,
            },
            detail: {
              show: false,
            },
            data: [
              {
                sensorId: "pressureOut",
                value: 0,
                // this should match one of the keys in the object emitted by the data$ observable
                unit: showMetric ? "kP" : "PSI",
                progress: {
                  show: true,
                  width: 20,
                },
                detail: {
                  valueAnimation: true,
                },
                pointer: {
                  show: true,
                },
                itemStyle: {
                  color: "hsl(14.2,100%,78.4%)",
                  shadowColor: "#FD7349",
                },
              },
              {
                sensorId: "temperatureOut",
                value: 0,
                // this should match one of the keys in the object emitted by the data$ observable
                unit: showMetric ? "℃" : "℉",
                detail: {
                  valueAnimation: true,
                },
                progress: {
                  show: true,
                  width: 8,
                },
                itemStyle: {
                  color: "hsl(14.5,97.8%,63.5%)",
                  shadowColor: "#FD7349",
                },
              },
            ],
          },
        ],
      },
    },
    {
      sensorType: "current",
      measure: { dual: false, imperial: "A", metric: "A" },
      data$,
      options: {
        series: [
          {
            title: "Current",
            startAngle: 220,
            endAngle: -40,
            radius: "95%",
            center: ["50%", "60%"],
            type: "gauge",
            splitNumber: 5,
            min: 0,
            max: 50,
            progress: {
              show: true,
              overlap: false,
              roundCap: false,
            },
            pointer: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                width: 35,
              },
            },
            axisTick: {
              distance: -45,
              splitNumber: 5,
              lineStyle: {
                width: 2,
                color: "#999",
              },
            },
            splitLine: {
              distance: -52,
              length: 8,
              lineStyle: {
                width: 2,
                color: "#999",
              },
            },
            axisLabel: {
              distance: 7,
              color: "#999",
              fontSize: 10,
            },
            detail: {
              show: false,
            },
            data: [
              {
                sensorId: "current1",
                name: "Hermetic",
                unit: "A",
                value: 0,
                itemStyle: {
                  color: "hsl(191.4,100%,49.4%)",
                  shadowColor: "#FD7349",
                },
                // this should match one of the keys in the object emitted by the data$ observable
              },
              {
                sensorId: "current2",

                value: 0,
                name: "Fan",
                // this should match one of the keys in the object emitted by the data$ observable
                unit: "A",
                itemStyle: {
                  color: "#0096ba",
                  shadowColor: "#FD7349",
                },
              },
            ],
          },
        ],
      },
    },
  ];

  return (
    <div className="relative flex min-h-screen flex-col">
      <div className="fixed top-0 left-0 right-0 z-30 shadow-md">
        <HeaderDashboard />
      </div>
      <div className="h-screen flex justify-center">
        <div className="sm:container max-w-screen-2xl items-center bg-transparent dark:bg-transparent">
          <div className="flex-1 space-y-4 w-full mt-16 sm:p-6 rounded-lg">
            <div className="flex-col w-full items-center sm:space-y-8 content-start">
              <h2 className="text-3xl font-bold tracking-tighter font-inter heading-tight text-start md:text-4xl lg:leading-[1.1] mb-4 dark:text-blue-brand">
                Dashboard
              </h2>
              {isNewUser ? "" : <WelcomeUser />}
              <Card className="border border-orange-vivid/80">
                <CardHeader>
                  <CardTitle>Your system is at risk</CardTitle>
                  <CardDescription className="max-w-2xl text-sm font-ligth text-foreground">
                    The system has detected a loss in refrigerant gas pressure (probable leak).
                  </CardDescription>
                </CardHeader>
              </Card>
            </div>
            <div className="mt-16">
              <Tabs defaultValue={deviceId} value={deviceId} className="mt-4 sm:mt-8 space-y-4">
                <TabsList>
                  {Object.entries(devices).map(([id, device]) => {
                    return (
                      <TabsTrigger key={id} value={id}>
                        {device.name ?? id}
                      </TabsTrigger>
                    );
                  })}
                </TabsList>
                {Object.entries(devices).map(([id, device]) => {
                  return (
                    <TabsContent key={id} value={id} className="space-y-4">
                      <main className="grid flex-1 grid-cols-1 sm:py-0">
                        <section className="flex flex-row space-y-1.5 p-3 text-sm text-muted-foreground justify-between">
                          <div className="flex flex-col text-left ">
                            <h3>{`Device: ${id}`}</h3>
                            <h3>Subscription: {device.subscription.active ? "Active" : "Inactive"}</h3>
                            {device.subscription?.lastPayment && (
                              <h3>
                                {`Last Payment: ${format(
                                  device.subscription.lastPayment,
                                  "MM/dd/yyyy"
                                )}, ${formatDistance(device.subscription.lastPayment, new Date(), {
                                  locale: enUS,
                                  addSuffix: true,
                                })}`}
                              </h3>
                            )}
                            <h3>Refrigerant: {device.hardware?.refrigerant ?? "unknown"}</h3>
                          </div>
                          <div className="flex top-0 items-start">
                            <div className="flex flex-row items-center space-x-1">
                              <Checkbox id="terms1" onCheckedChange={(_) => toggleShowMetric()} />
                              <div className="leading-none">
                                <label
                                  htmlFor="terms1"
                                  className="flex items-center text-xs font-light text-muted-foreground leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                                >
                                  SI
                                </label>
                              </div>
                            </div>
                          </div>
                        </section>

                        <div className="grid md:grid-cols-2 2xl:grid-cols-12 gap-8">
                          {data$ &&
                            sensorList.map(({ sensorType, options, data$ }, index) => {
                              return (
                                <Card key={index} className={`p-4 relative shadow-sm md:col-span-1 2xl:col-span-4`}>
                                  <CardHeader className="p-0">
                                    <CardTitle className="flex pb-6 justify-center font-semibold leading-none tracking-tight">
                                      {options.series.map((option, i) => {
                                        return (
                                          <strong
                                            key={i}
                                            className="flex font-semibold leading-none sm:text-lg md:text-2xl tracking-tight text-foreground dark:text-blue-brand justify-end"
                                          >
                                            {option.title}
                                          </strong>
                                        );
                                      })}
                                    </CardTitle>
                                  </CardHeader>
                                  <CardContent className="sm:items-stretch sm:items-center p-0 bg-transparent">
                                    <div className="flex w-full justify-center">
                                      <div className="w-full absolute sm:w-1/2 overflow-hidden z-100 pt-24">
                                        {sensorType.includes("current") ? (
                                          <div className="pt-2"></div>
                                        ) : (
                                          <span className="flex justify-center text-sm w-full text-foreground text-center dark:text-blue-brand pb-4">
                                            {device.hardware?.refrigerant ?? "unknown"}
                                          </span>
                                        )}
                                        {
                                          // we only support one series for now
                                          options.series[0].data.map((data, index) => (
                                            <>
                                              <span className="flex justify-center text-sm w-full text-foreground text-center dark:text-blue-brand pt-2">
                                                {data.name}
                                              </span>
                                              <DigitalValues key={index} data={data} data$={data$} />
                                            </>
                                          ))
                                        }
                                        {sensorType === "pressure" && (
                                          <SuperHeat
                                            data$={data$}
                                            temperatureSensorId="temperatureIn"
                                            pressureSensorId="pressureIn"
                                            showMetric={showMetric}
                                          />
                                        )}
                                        {sensorType === "temperature" && (
                                          <SubCooling
                                            data$={data$}
                                            temperatureSensorId="temperatureOut"
                                            pressureSensorId="pressureOut"
                                            showMetric={showMetric}
                                          />
                                        )}
                                      </div>
                                    </div>
                                    <ResponsiveContainer width="100%" height="100%" className={"flex items-stretch"}>
                                      <EChartsStreamVisualizer
                                        options={options}
                                        data$={data$}
                                        clear$={clear$}
                                        minHeight="300px"
                                      />
                                    </ResponsiveContainer>
                                  </CardContent>
                                  <CardDescription className="flex justify-center pt-6 text-slate-400">
                                    {`Last update: ${
                                      lastSensorUpdate[sensorType]
                                        ? formatDistance(lastSensorUpdate[sensorType], new Date())
                                        : "-"
                                    }`}
                                  </CardDescription>
                                </Card>
                              );
                            })}
                        </div>

                        <Card className="mt-4">
                          <CardHeader>
                            <CardTitle className="border-0 shadow-none">Recent Historical Data</CardTitle>
                          </CardHeader>
                          <CardContent>
                            <ResponsiveContainer width="100%" height="100%" className={"flex items-stretch"}>
                              <EChartsStreamVisualizer
                                options={{
                                  name: "Pressure",
                                  animation: false,
                                  tooltip: {
                                    trigger: "axis",
                                    axisPointer: {
                                      type: "cross",
                                    },
                                    formatter: (params) => {
                                      console.log("params", params);
                                      let html = `${new Date(params[0].value[0]).toLocaleString()}<br/>`;
                                      html += "<br />";
                                      const temperatureOut = params.find((p) => p.seriesName === "temperatureOut");
                                      const temperatureIn = params.find((p) => p.seriesName === "temperatureIn");
                                      const pressureOut = params.find((p) => p.seriesName === "pressureOut");
                                      const pressureIn = params.find((p) => p.seriesName === "pressureIn");
                                      const current1 = params.find((p) => p.seriesName === "current1");
                                      const current2 = params.find((p) => p.seriesName === "current2");
                                      const current3 = params.find((p) => p.seriesName === "current3");

                                      if (temperatureIn && pressureIn) {
                                        html += "<b>Low Line</b> <br>";
                                        html += `<div style="background-color: ${
                                          pressureIn.color
                                        }; height: 8px; width: 8px;  border-radius: 50%; display: inline-block;"> </div> Pressure: <b>${Math.round(
                                          pressureIn.value[1]
                                        )} </b>${showMetric ? "kP" : "PSI"}<br/>`;

                                        html += `<div style="background-color: ${
                                          temperatureIn.color
                                        }; height: 8px; width: 8px;  border-radius: 50%; display: inline-block;"> </div> Temperature: <b>${Math.round(
                                          temperatureIn.value[1]
                                        )} </b>${showMetric ? "℃" : "℉"}<br/>`;

                                        if (devices[deviceId].hardware?.refrigerant) {
                                          const vsat = getPsiToF(
                                            devices[deviceId].hardware?.refrigerant as Refrigerant
                                          )(pressureIn.value[1]);
                                          html += `Vapor Saturation: <b>${Math.round(vsat)} </b>${
                                            showMetric ? "℃" : "℉"
                                          }<br/>`;
                                          html += `Superheat: <b>${Math.round(temperatureIn.value[1] - vsat)} </b>${
                                            showMetric ? "℃" : "℉"
                                          }<br/>`;
                                        }

                                        html += `<br/>`;
                                      }

                                      if (temperatureOut && pressureOut) {
                                        html += "<b>High Line</b> <br>";
                                        html += `<div style="background-color: ${
                                          pressureOut.color
                                        }; height: 8px; width: 8px;  border-radius: 50%; display: inline-block;"> </div> Pressure: <b>${Math.round(
                                          pressureOut.value[1]
                                        )} </b></b>${showMetric ? "kP" : "PSI"}<br/>`;

                                        html += `<div style="background-color: ${
                                          temperatureOut.color
                                        }; height: 8px; width: 8px;  border-radius: 50%; display: inline-block;"> </div> Temperature: <b>${Math.round(
                                          temperatureOut.value[1]
                                        )} </b>${showMetric ? "℃" : "℉"}<br/>`;

                                        if (devices[deviceId].hardware?.refrigerant) {
                                          const lsat = getPsiToF(
                                            devices[deviceId].hardware?.refrigerant as Refrigerant
                                          )(pressureOut.value[1]);
                                          html += `Liquid Saturation: <b>${Math.round(lsat)} </b>${
                                            showMetric ? "℃" : "℉"
                                          }<br/>`;
                                          html += `Subcooling: <b>${Math.round(lsat - temperatureOut.value[1])} </b>${
                                            showMetric ? "℃" : "℉"
                                          }<br/>`;
                                        }
                                        html += `<br/>`;
                                      }

                                      if (current1) {
                                        html += "<b>Current</b> <br>";
                                        html += `<div style="background-color: ${
                                          current1.color
                                        }; height: 8px; width: 8px;  border-radius: 50%; display: inline-block;"> </div> Current 1: <b>${Math.round(
                                          current1.value[1]
                                        )} </b>A<br/>`;
                                      }

                                      if (current2) {
                                        html += `<div style="background-color: ${
                                          current2.color
                                        }; height: 8px; width: 8px;  border-radius: 50%; display: inline-block;"> </div> Current 2: <b>${Math.round(
                                          current2.value[1]
                                        )} </b>A<br/>`;
                                      }

                                      if (current3) {
                                        html += `<div style="background-color: ${
                                          current3.color
                                        }; height: 8px; width: 8px;  border-radius: 50%; display: inline-block;"> </div> Current 3: <b>${Math.round(
                                          current3.value[1]
                                        )} </b>A<br/>`;
                                      }

                                      return html;
                                    },
                                  },
                                  xAxis: [
                                    {
                                      min: "dataMin",
                                      max: "dataMax",
                                      type: "time",
                                      gridIndex: 0,
                                    },
                                    {
                                      min: "dataMin",
                                      max: "dataMax",
                                      type: "time",
                                      gridIndex: 1,
                                    },
                                  ],
                                  boundaryGap: true,
                                  grid: [
                                    {
                                      height: "35%",
                                    },
                                    {
                                      top: "55%",
                                      height: "30%",
                                    },
                                  ],
                                  yAxis: [
                                    {
                                      type: "value",
                                      name: "Pressure",
                                      position: "right",
                                      alignTicks: true,
                                      axisLabel: {
                                        formatter: (value) => `${Math.round(value)} ${showMetric ? "kP" : "PSI"} `,
                                      },
                                      gridIndex: 0,
                                    },
                                    {
                                      type: "value",
                                      name: "Temperature",
                                      position: "left",
                                      alignTicks: true,
                                      axisLabel: {
                                        formatter: (value) => `${Math.round(value)} ${showMetric ? "℃" : "℉"} `,
                                      },
                                      gridIndex: 0,
                                    },
                                    {
                                      type: "value",
                                      name: "Current",
                                      position: "right",
                                      alignTicks: true,
                                      gridIndex: 1,
                                      axisLabel: {
                                        formatter: (value) => `${Math.round(value)} A`,
                                      },
                                    },
                                  ],
                                  dataZoom: [
                                    {
                                      show: true,
                                      type: "slider",
                                      top: "90%",
                                      xAxisIndex: [0, 1],
                                    },
                                  ],
                                  series: [
                                    {
                                      name: "temperatureIn",
                                      color: "hsl(14.5,97.8%,63.5%)",
                                      data: [],
                                      xAxisIndex: 0,
                                      yAxisIndex: 1,
                                      type: "line",
                                    },
                                    {
                                      name: "temperatureOut",
                                      color: "hsl(14.2,100%,78.4%)",
                                      data: [],
                                      xAxisIndex: 0,
                                      yAxisIndex: 1,
                                      type: "line",
                                    },
                                    {
                                      name: "pressureIn",
                                      color: "hsl(191.9,100%,84.1%)",
                                      data: [],
                                      type: "bar",
                                      xAxisIndex: 0,
                                      yAxisIndex: 0,
                                      backgroundStyle: {
                                        color: "rgba(180, 180, 180, 0.2)",
                                      },
                                    },
                                    {
                                      name: "pressureOut",
                                      color: "hsl(191.5,100%,51.8%)",
                                      type: "bar",
                                      data: [],
                                      xAxisIndex: 0,
                                      yAxisIndex: 0,
                                    },
                                    {
                                      name: "current1",
                                      color: "hsl(191.4,100%,49.4%)",
                                      data: [],
                                      type: "line",
                                      xAxisIndex: 1,
                                      yAxisIndex: 2,
                                    },
                                    {
                                      name: "current2",
                                      color: "#0096ba",
                                      data: [],
                                      type: "line",
                                      xAxisIndex: 1,
                                      yAxisIndex: 2,
                                    },
                                    {
                                      name: "current3",
                                      color: "#002730",
                                      data: [],
                                      type: "line",
                                      xAxisIndex: 1,
                                      yAxisIndex: 2,
                                    },
                                  ],
                                }}
                                data$={data$}
                                clear$={clear$}
                                minHeight={"600px"}
                              />
                            </ResponsiveContainer>
                          </CardContent>
                        </Card>
                        <Card className="mt-4">
                          <CardHeader>
                            <CardTitle>Events</CardTitle>
                            <CardDescription>You have 25 unread events this month.</CardDescription>
                          </CardHeader>
                          <CardContent></CardContent>
                        </Card>
                        <Card className="mt-4">
                          <CardHeader>
                            <CardTitle>Reports</CardTitle>
                            <CardDescription>Please select the period to analyze</CardDescription>
                          </CardHeader>
                          <CardContent>
                            <Reports />
                          </CardContent>
                        </Card>
                      </main>
                    </TabsContent>
                  );
                })}
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
