import { SendPasswordResetEmailForm } from "./SendPasswordResetEmailForm";

export const PasswordResetRequest = () => {
  return (
    <>
      <div className="flex h-screen justify-center items-center">
        <div className="container relative flex-col items-center justify-center">
          <div className="flex justify-center">
            <div className="flex px-4 sm:p-8 grow max-w-md flex-col bg-White/30 justify-center space-y-6 sm:border sm:shadow-xl sm:shadow-blue-brand/10 sm:rounded-xl">
              <div className="flex flex-col text-center">
                <h1 className="text-2xl font-semibold tracking-tight">Reset Password</h1>
                <p className="text-sm text-muted-foreground text-center pb-3 pt-6 sm:pt-10">
                  Please write your email address below and complete the captcha verification to request a password
                  change.
                </p>
              </div>
              <SendPasswordResetEmailForm />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
