import { Subject } from "rxjs";

function mapData(data: { time: string; [sensorId: string]: number }[]) {
  const result = {};

  data.forEach((item) => {
    Object.keys(item).forEach((key) => {
      if (key !== "blobId" && key !== "kernelId" && key !== "time") {
        if (!result[key]) {
          result[key] = [];
        }
        result[key].push({
          time: new Date(item.time).getTime(),
          value: item[key],
        });
      }
    });
  });

  return result;
}

export const getStreamFromWS: (kernelId: string) => Subject<Record<string, { time: number; value: number }[]>> = (
  kernelId: string
) => {
  const subject = new Subject<Record<string, { time: number; value: number }[]>>();

  const ws = new WebSocket(`wss://api.cool-guardian.com/outbound-websocket/${kernelId}`);

  ws.onopen = () => {
    console.log("Connection opened");
  };

  ws.onmessage = (event) => {
    const data = JSON.parse(event.data);
    const mappedData = mapData(data);
    //console.log("Data received", data, "parsed", data, "mapped", mappedData);
    subject.next(mappedData);
  };

  ws.onerror = (error) => {
    console.error("Error", error);
    subject.error(error);
  };

  ws.onclose = () => {
    console.log("Connection closed");
    subject.complete();
  };

  return subject;
};
