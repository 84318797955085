import React from "react";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { Customers } from "./data/Customers";

interface CustomerTable {
  customerTable?: typeof Customers;
}

const CustomerTable = () => {
  const customerKeys: string[] = Object.keys(Customers[0]);

  return (
    <Table>
      <TableHeader>
        <TableRow>
          {customerKeys.slice(1).map((column) => {
            //   console.log(column);
            return <TableHead>{column}</TableHead>;
          })}
        </TableRow>
      </TableHeader>
      <TableBody>
        {Customers.map((elem) => (
          <TableRow key={elem.id}>
            <TableCell className="font-medium">{elem.firstName}</TableCell>
            <TableCell>{elem.lastName}</TableCell>
            <TableCell>{elem.mailingAddress}</TableCell>
            <TableCell>{elem.phoneNumber}</TableCell>
            <TableCell>{elem.mailingAddress}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default CustomerTable;
