import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { PlusCircledIcon } from "@radix-ui/react-icons";
import { InputFormDevice } from "./inputFormDevice";
import { InputFormCustomer } from "../customer/inputFormCustomer";

const AddDevice = () => {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button className="text-foreground">
          <PlusCircledIcon className="mr-2 h-4 w-4" />
          Register New Device
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-screen-lg">
        <DialogHeader>
          <DialogTitle>Add device registration information</DialogTitle>
          <DialogDescription>Click submit when you're done.</DialogDescription>
        </DialogHeader>
        <InputFormCustomer />
      </DialogContent>
    </Dialog>
  );
};

export default AddDevice;
