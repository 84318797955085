import { UpdateEmailForm } from "./UpdateEmailForm";

export const UpdateEmail = () => {

  return (
    <>
      <div className="relative pt-10 pb-24 px-3 flex items-center justify-center ">
        <div className="relative sm:w-[520px] justify-center sm:border">
          <div className="sm:p-8">
            <div className="mx-auto flex w-full flex-col justify-center space-y-3 sm:space-y-6 sm:w-[350px]">
              <div className="flex flex-col text-center">
                <h1 className="text-2xl font-semibold tracking-tight">
                  Update Email
                </h1>
                <p className="text-sm text-muted-foreground text-center pb-3 pt-6 sm:pt-10">
                Please write your new email address below.
                </p>
              </div>
              <UpdateEmailForm/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};