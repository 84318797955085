import { useEffect, useState } from "react";
import { r410aPsiToF } from "@/utils/refrigerants.ts";
import convert from "convert";

export const SuperHeat = ({ temperatureSensorId, pressureSensorId, data$, showMetric }) => {
  const [superHeat, setSuperHeat] = useState<number | null>(null);
  const [saturationTemperature, setSaturationTemperature] = useState<number | null>(null);

  // TODO: pick the right function based on the refrigerant
  const psiToSaturationTemperature = r410aPsiToF;

  useEffect(() => {
    const subscription = data$.subscribe((d) => {
      const values = d[pressureSensorId];
      if (!values) {
        console.warn("No values for sensor", "superheat", "data", d);
        return;
      }
      const pressure = values[values.length - 1].value;
      const st = psiToSaturationTemperature(pressure);
      setSaturationTemperature(st);
      const valuesTemperature = d[temperatureSensorId];
      if (!valuesTemperature) {
        console.warn("No values for sensor", "superheat", "data", d);
        return;
      }
      const temperature = valuesTemperature[valuesTemperature.length - 1].value;
      const sh = temperature - st;
      setSuperHeat(sh);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return (
    <div>
      <div className="flex justify-center items-center pt-4">
        <span className="font-normal text-foreground dark:text-blue-brand pr-4">Superheat</span>
        <span className="text-xl font-bold text-foreground dark:text-blue-brand">
          {superHeat ? Math.round(showMetric ? convert(superHeat, "fahrenheit").to("celsius") : superHeat) : "-"}
        </span>
        <span className="pl-2 text-sm text-foreground dark:text-blue-brand">{showMetric ? " °C" : " °F"}</span>
      </div>
      <div className="flex justify-center items-center pt-1">
        <span className="font-normal text-foreground dark:text-blue-brand pr-4">VSAT</span>
        <span className="text-xl font-bold text-foreground dark:text-blue-brand">
          {saturationTemperature
            ? Math.round(
                showMetric ? convert(saturationTemperature, "fahrenheit").to("celsius") : saturationTemperature
              )
            : "-"}
        </span>
        <span className="pl-2 text-sm text-foreground dark:text-blue-brand">{showMetric ? " °C" : " °F"}</span>
      </div>
    </div>
  );
};
