import { SetNewPasswordForm } from "./SetNewPasswordForm";

interface SetNewPasswordProps {
  code: string;
}

export const SetNewPassword = ({ code }: SetNewPasswordProps) => {
  return (
    <>
      <div className="flex h-screen justify-center items-center">
        <div className="container relative flex-col items-center justify-center">
          <div className="flex justify-center">
            <div className="flex px-4 sm:p-8 grow max-w-md flex-col bg-White/30 justify-center space-y-6 sm:border sm:shadow-xl sm:shadow-blue-brand/10 sm:rounded-xl">
              <div className="flex flex-col space-y-2 text-center">
                <h1 className="text-xl font-semibold tracking-tight sm:text-2xl">Reset Password</h1>
                <p className="flex w-full justify-center font-semibold text-sm text-muted-foreground text-start pb-3 pt-6 sm:pt-10">
                  Please enter a password that meets the following requirements:
                </p>
                <ul className="list-disc px-4 text-sm text-muted-foreground text-start">
                  <li>It should include letters and numbers.</li>
                  <li>It should combine uppercase and lowercase letters.</li>
                  <li>The password length must be equal to or greater than 6 characters.</li>
                </ul>
              </div>
              <SetNewPasswordForm code={code} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
