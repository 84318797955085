import { create, StoreApi, UseBoundStore } from "zustand";
import { initializeApp } from "firebase/app";
import {
  createUserWithEmailAndPassword,
  getAuth,
  GithubAuthProvider,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  verifyPasswordResetCode,
  confirmPasswordReset,
  checkActionCode,
  applyActionCode,
  User,
  sendEmailVerification,
  updateEmail,
  updatePassword,
  ActionCodeSettings,
  verifyBeforeUpdateEmail,
  ActionCodeInfo,
  Auth,
  signInWithRedirect,
  GoogleAuthProvider,
  isSignInWithEmailLink,
  signInWithEmailLink,
  sendSignInLinkToEmail,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAeP6h2kqCwB4pxdvqV5ECeYem_sLM1YHQ",
  authDomain: "cool-guardian-ff906.firebaseapp.com",
  projectId: "cool-guardian-ff906",
  storageBucket: "cool-guardian-ff906.appspot.com",
  messagingSenderId: "957671421984",
  appId: "1:957671421984:web:4e0c917d34ac04d4342445",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);

const githubProvider = new GithubAuthProvider();
const googleProvider = new GoogleAuthProvider();

export type AuthStoreState = {
  loading: boolean;
  user: User | null;
  initialized: boolean;
  error: string | null;
  isNewUser: boolean;
  isAcceptTerms: boolean;
  isAcceptNotificationPartner: boolean;
};

export type AuthStoreActions = {
  clearError: () => void;
  setUser: (user: User | null) => void;
  signUp: (email: string, password: string) => Promise<void>;
  signIn: (email: string, password: string) => Promise<void>;
  signInWithGoogle: () => Promise<void>;
  sendSignInLinkToEmail: (email: string) => Promise<void>;
  signInWithEmailLink: () => Promise<void>;
  signOut: () => Promise<void>;
  generatePasswordResetEmail: (email: string) => Promise<void>;
  verifyPasswordResetCode: (actionCode: string) => Promise<string>;
  confirmPasswordReset: (actionCode: string, newPassword: string) => Promise<void>;
  applyActionCode: (auth: Auth, actionCode: string) => Promise<void>;
  checkActionCode: (auth: Auth, code: string) => Promise<ActionCodeInfo>;
  sendEmailVerification: (user: User, actionCodeSettings?: ActionCodeSettings | null) => Promise<void>;
  verifyBeforeUpdateEmail(user: User, newEmail: string, actionCodeSettings?: ActionCodeSettings | null): Promise<void>;
  updateEmail(user: User, newEmail: string): Promise<void>;
  updatePassword(newPassword: string): Promise<void>;
  setisAcceptTerm: (isAcceptTerms: boolean) => void;
  setIsAcceptNotificationPartner: (isAcceptNotificationP: boolean) => void;
};

export type AuthStore = AuthStoreState & AuthStoreActions;

export const useAuthStore: UseBoundStore<StoreApi<AuthStore>> = create<AuthStore>((set, get) => ({
  loading: true,
  isNewUser: false,
  initialized: false,
  user: null,
  error: null,
  isAcceptTerms: true,
  isAcceptNotificationPartner: true,
  clearError: () => {
    set(() => ({ error: null }));
  },
  setUser: (user: User | null) => {
    console.log("setUser", user);
    set(() => ({
      user,
      initialized: true,
      loading: false,
    }));
  },
  setisAcceptTerm: (isAcceptTerms) => {
    console.log("Status of approval of terms", isAcceptTerms);
    set(() => ({
      isAcceptTerms: isAcceptTerms,
    }));
  },
  setIsAcceptNotificationPartner: (isAcceptNotificationPartner) => {
    console.log("Status of approval of notification to partner", isAcceptNotificationPartner);
    set(() => ({
      isAcceptNotificationPartner,
    }));
  },
  async signUp(email: string, password: string) {
    set(() => ({ loading: true }));
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        console.log("Signed up", userCredential);
        set(() => ({ user: userCredential.user }));
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error(`Unable to create user, error code: ${errorCode}, ${errorMessage}`);
        set(() => ({ error: error.message }));
      })
      .finally(() => set(() => ({ loading: false })));
  },
  async signIn(email: string, password: string) {
    set(() => ({ loading: true }));
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        //  console.log("Signed in", userCredential.user);
        const metadata = auth.currentUser.metadata;
        const isNewUser = metadata.creationTime === metadata.lastSignInTime;
        // set the state
        set(() => ({
          user: userCredential.user,
          isNewUser,
        }));
      })
      .catch((error) => {
        // console.error(`Unable to sign in user, error code: ${error.code}, ${error.message}`);
        set(() => ({ error: error.message }));
      })
      .finally(() => set(() => ({ loading: false })));
  },
  async signInWithGoogle() {
    console.log("Sign in with google");
    set(() => ({ loading: true }));
    signInWithPopup(auth, googleProvider)
      .then((userCredential) => {
        // Signed in
        set(() => ({ user: userCredential.user }));
        console.log("Signed in with google", userCredential.user);
      })
      .catch((error) => {
        console.error(`Unable to sign in user, error code: ${error.code}, ${error.message}`);
        set(() => ({ error: error.message }));
      })
      .finally(() => set(() => ({ loading: false })));
  },
  async sendSignInLinkToEmail(email: string) {
    const actionCodeSettings = {
      // URL you want to redirect back to. The domain (www.example.com) for this
      // URL must be in the authorized domains list in the Firebase Console.
      url: "https://www.example.com/finishSignUp?cartId=1234",
      // This must be true.
      handleCodeInApp: true,
      iOS: {
        bundleId: "com.example.ios",
      },
      android: {
        packageName: "com.example.android",
        installApp: true,
        minimumVersion: "12",
      },
      dynamicLinkDomain: "example.page.link",
    };
    sendSignInLinkToEmail(auth, email, actionCodeSettings)
      .then(() => {
        // The link was successfully sent. Inform the user.
        // Save the email locally so you don't need to ask the user for it again
        // if they open the link on the same device.
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ...
      });
  },
  async signInWithEmailLink() {
    console.log("Sign in with google");
    set(() => ({ loading: true }));
    signInWithPopup(auth, googleProvider)
      .then((userCredential) => {
        // Signed in
        set(() => ({ user: userCredential.user }));
        console.log("Signed in with google", userCredential.user);
      })
      .catch((error) => {
        console.error(`Unable to sign in user, error code: ${error.code}, ${error.message}`);
        set(() => ({ error: error.message }));
      })
      .finally(() => set(() => ({ loading: false })));
  },
  async signOut() {
    set(() => ({ loading: true }));
    auth
      .signOut()
      .then(() => {
        console.log("Signed out successfully");
        set(() => ({ user: null }));
      })
      .catch((error) => {
        // errorStore.setError(error);
        console.error("Unable to sign out", error.code, error.message);
        set(() => ({ error: error.message }));
      })
      .finally(() => set(() => ({ loading: false })));
  },
  async generatePasswordResetEmail(email: string) {
    set(() => ({ loading: true }));
    sendPasswordResetEmail(auth, email)
      .then((value) => {
        // Signed in
        console.log("Email send", value);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error(`Unable to create user, error code: ${errorCode}, ${errorMessage}`);
        set(() => ({ error: error.message }));
      })
      .finally(() => set(() => ({ loading: false })));
  },
  async generateEmailVerificationLink(email: string) {
    set(() => ({ loading: true }));
    sendPasswordResetEmail(auth, email)
      .then((email) => {
        // Signed in
        console.log("EmailSend", email);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error(`Unable to create user, error code: ${errorCode}, ${errorMessage}`);
        set(() => ({ error: error.message }));
      })
      .finally(() => set(() => ({ loading: false })));
  },
  async verifyPasswordResetCode(actionCode: string) {
    return await verifyPasswordResetCode(auth, actionCode);
  },
  async confirmPasswordReset(actionCode: string, newPassword: string) {
    return await confirmPasswordReset(auth, actionCode, newPassword);
  },
  async applyActionCode(auth: Auth, actionCode: string) {
    return await applyActionCode(auth, actionCode);
  },
  async checkActionCode(auth: Auth, actionCode: string) {
    return await checkActionCode(auth, actionCode);
  },
  async sendEmailVerification(user: User, actionCodeSettings?: ActionCodeSettings | null) {
    return await sendEmailVerification(user);
  },
  async verifyBeforeUpdateEmail(user: User, newEmail: string, actionCodeSettings?: ActionCodeSettings | null) {
    return await verifyBeforeUpdateEmail(user, newEmail);
  },
  async updateEmail(user: User, newEmail: string) {
    return await updateEmail(user, newEmail);
  },
  async updatePassword(newPassword: string) {
    const user = get().user;
    if (user) {
      return await updatePassword(user, newPassword);
    } else {
      console.error("Unable to update password, user is not signed in");
    }
  },
}));

if (isSignInWithEmailLink(auth, window.location.href)) {
  console.log("Authenticating user with email link");
  const searchParams = new URLSearchParams(window.location.search);
  const continueUrl = searchParams.get("continueUrl");
  const email = continueUrl.split("email=")[1];
  console.log("Email -", email);
  signInWithEmailLink(auth, email, window.location.href)
    .then((result) => {
      console.log("Signed in with email link", result);
      console.log("Redirecting to", continueUrl);
      window.location.href = continueUrl;
    })
    .catch((error) => {
      console.error("Unable to sign in with email link", error.code, error.message);
    });
} else {
  auth.onAuthStateChanged(async (user) => {
    console.log("auth state changed user", user);
    useAuthStore.getState().setUser(user);
  });
}
