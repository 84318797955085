import { HeaderAuth } from "@/components/dashboard/header/headerAuth";
import React from "react";
import { Outlet } from "react-router-dom";

const AuthMain = () => {
  return (
    <>
      <div className="relative flex min-h-screen flex-col bg-background">
        <div className="fixed top-0 left-0 right-0 z-30 shadow-md">
          <HeaderAuth />
        </div>
        <Outlet />
      </div>
    </>
  );
};

export default AuthMain;
